:root {
  --color-link-blue: #0a72c7;
  --color-main: #2f4659;
  --color-text-gray: #666;
  --color-aa-border: #666;
  --color-text-black: #333;

  --color-success: #408040;
  /* 0.15 alpha */
  --color-success-bg: #e3ece3;

  --color-warning: #ffae42;
  --color-warning-bg: #fff1df;
}

body {
  padding: 0 20px;
}

a {
  color: var(--color-link-blue);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:visited {
  color: var(--color-link-blue);
}

#root {
  position: relative;
  padding: 0 10px;
}

.charts {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

.chart {
  flex: 0 0;
  width: 50%;
  min-width: 50%;
}
@media only screen and (max-width: 799px) {
  .chart {
    width: 100%;
    min-width: 100%;
  }
}

.chart.chart-maximized {
  width: 100%;
  min-width: 100%;
}

.th-action {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title-with-action {
  display: flex;
  align-items: center;
}

.title-with-action button {
  cursor: pointer;
  background: none;
  margin: 0 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.title-with-action button:hover {
  background-color: rgba(47, 72, 88, 0.1);
}

.link {
  color: var(--color-main);
  cursor: pointer;
}

.device-data {
  display: inline-block;
  border: 2px solid var(--color-aa-border);
  padding: 20px;
  margin: 10px 0 0;
  position: relative;
}

.device-data thead {
  vertical-align: top;
}

.device-data th {
  text-align: left;
  padding-right: 25px;
}

.device-titles {
  display: flex;
  flex: 0;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 40px;
}
.device-titles:first-of-type {
  margin-top: 0;
}

.device-titles h2 {
  display: inline-block;
  margin: 0 20px 0 0;
}
.device-titles h2 svg {
  visibility: hidden;
}
.device-titles h2:hover svg {
  visibility: visible;
}

.device-titles button {
  cursor: pointer;
  border: 1px solid var(--color-main);
  border-radius: 3px;
  background-color: transparent;
  margin-right: 10px;
}
.device-titles button:hover {
  background-color: rgba(47, 72, 88, 0.1);
}

.data-value {
  display: inline-block;
  width: 50px;
}
.data-unit {
  color: var(--color-text-gray);
  font-size: 14px;
}
.device-date {
  color: var(--color-text-gray);
}

.registers {
  padding: 0 0 65px;
}

.registers a {
  color: var(--color-text-black);
}

dl {
  margin: 5px 0;
}
dt {
  display: inline-block;
  width: 130px;
}

dd {
  display: inline-block;
  width: 90px;
  margin: 0;
}

circle.svg-line-chart-point {
  display: none;
}

.login-header {
  position: absolute;
  right: 0;
}

.login-header .button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  font-size: 14px;
  padding: 4px 20px 10px;
  width: auto;
  font-weight: bold;
}

.top-part {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.top-part.loggedin {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.button.headerbutton {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 0;
}

.button {
  background-color: var(--color-main);
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  display: inline-block;
  margin: 0 20px 10px 0;
  text-decoration: none;
  cursor: pointer;
}

a.button:visited {
  color: #fff;
}

.button.button-secondary {
  border: 2px solid var(--color-main);
  padding: 10px 20px;
  color: var(--color-text-black);
  background: none;
}

a.button.button-secondary:visited {
  color: var(--color-text-black);
}

.tarjous {
  display: flex;
  align-items: baseline;
}

@media (max-width: 768px) {
  .tarjous {
    flex-direction: column;
  }
}


.offer {
  flex: 1;
  margin: 0;
}

p {
  margin: 10px 0;
}

label {
  display: block;
  margin: 10px 0;
}

label span {
  display: inline-block;
}

input[type="text"] {
  width: 250px;
  border: 1px solid var(--color-aa-border);
  border-radius: 2px;
}
.postals {
  display: inline-block;
}
.postal-code {
  width: 60px;
  margin-right: 7px;
}
.postal-city {
  width: 130px;
}
textarea {
  width: 363px;
  max-width: 80vw;
  min-height: 150px;
  border: 1px solid var(--color-aa-border);
  border-radius: 2px;
}

.contact-page {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .contact-page {
    flex-wrap: wrap;
  }
}

.pricelist h3 {
  font-size: 16px;
  margin-bottom: 5px;
}

.contact-form label span {
  display: inline-block;
  min-width: 120px;
  width: 120px;
}

.contact-form label span.full {
  width: 100%;
}

.footer-content {
  display: flex;
}
.contact {
  display: inline-block;
}
.bottom-image {
  display: inline-block;
}

.bottom-image img {
  max-width: 100%;
  margin: 0 20px;
}

.contact-details .bottom-image {
  display: block;
}
.contact-details img {
  max-width: 50%;
}

h1,
h2 {
  margin-top: 20px;
}

.thanks {
  margin-bottom: 60px;
}

.error {
  margin: 30px 0;
  padding: 30px;
  border: 2px solid red;
  display: inline-block;
}

.device-table th {
  padding: 5px 15px;
  text-align: left;
}

.device-table td {
  padding: 0 15px;
}

.edit-device-name {
  cursor: pointer;
}

.user-row td {
  border-bottom: 2px solid var(--color-main);
  padding-top: 20px;
}

.button-in-table {
  padding: 0;
  color: var(--color-link-blue);
  background-color: transparent;
  margin: 0 10px;
}

a.button-in-table:visited {
  color: var(--color-link-blue);
}

.button-in-table::after {
  content: " >";
}

.data-row {
  margin: 10px 0;
}
.label {
  width: 160px;
  display: inline-block;
}

.user-data-row {
  display: flex;
  align-items: baseline;
}

.user-data-row h2 {
  flex: 1;
}

.cancel-button {
  display: flex;
  justify-content: flex-end;
}

.device-table {
  margin-bottom: 20px;
}

.remove-user {
  border: 3px solid red;
  padding: 7px 17px;
  background: transparent;
  color: var(--color-text-black);
}
a.remove-user:visited {
  color: var(--color-text-black);
}

/* Modal */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 10px 0;
}

.modal-header h2 {
  margin: 0;
}
.modal-body {
  flex: 1;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.ReactModal__Body--open {
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.ReactModal__Content--after-open {
  display: flex;
  flex-direction: column;
}

.message {
  position: fixed;
  top: 0;
  left: calc(50% - 160px);
  right: calc(50% - 160px);
  padding: 20px;
  border: 3px solid red;
  border-top: none;
  background: #fff;
  z-index: 2000;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.3);
}

.message-success {
  border-color: var(--color-success);
  background-color: var(--color-success-bg);
}
.message-warning {
  border-color: var(--color-warning);
  background-color: var(--color-warning-bg);
}

.notification-outdated {
  border: 3px solid var(--color-warning);
  background-color: var(--color-warning-bg);
  border-radius: 4px;
  padding: 20px;
  margin: 20px 0;
}

.link-button {
  padding: 0;
  border: none;
  cursor: pointer;
  color: var(--color-text-black);
  background: inherit;
}

.message .link-button {
  float: right;
}

.salasana-page .restore-form span {
  width: 160px;
  margin: 20px 0;
}

.datapage {
  display: flex;
  margin-top: 20px;
}

/* blink the cookie consent background */
@keyframes blink {
  0% {
    background-color: rgb(53, 53, 53);
  }
  50% {
    background-color: var(--color-main);
  }
  100% {
    background-color: rgb(53, 53, 53);
  }
}

iframe#webpack-dev-server-client-overlay {
  display:none!important
}
