.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.main-title {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.main-title h1 {
  margin: 5px 0 0 20px;
  font-family: "Prompt", sans-serif;
}

.main-title .slogan {
  margin: -9px 20px 0 20px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}
