#login-btn {
  background-color: var(--color-main);
  border: none;
  border-radius: 2px;
  padding: 5px 10px;
  margin-top: -5px;
  cursor: pointer;
}

#login-btn:hover {
  background-color: var(--color-link-blue);
}

#login-btn svg {
  width: 20px;
}

#login-btn path {
  fill: #fff;
}

.lock {
  position: relative;
}

#user-navi {
  position: absolute;
  background-color: #fff;
  right: 0;
  padding: 10px 0;
  border: 2px solid var(--color-main);
}

#user-navi ul {
  flex-direction: column;
}
#user-navi li {
  margin: 5px;
}

#user-navi ul a {
  margin: 0;
  white-space: nowrap;
  width: calc(100% - 8px);
  display: inline-block;
  border-bottom: 3px solid #fff;
}

#user-navi ul a:hover {
  border-bottom: 3px solid var(--color-link-blue);
}
#user-navi ul .active a {
  border-bottom: 3px solid var(--color-link-blue);
  font-weight: bold;
}

#navi-hidden-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
