.date-selection {
  display: inline-block;
  background-color: #eff4f8;
  position: relative;
  transition: width 0.2s;
  margin: 0 20px 0 -30px;
  padding: 20px 20px 0;

  .from {
    // display: inline-block;
  }
  .to {
    // display: inline-block;
  }

  input[type="text"] {
    width: 140px;
  }

  .visibility-btn {
    background-color: var(--color-main);
    border: none;
    border-radius: 2px;
    color: white;
    top: 15px;
    right: 0;
    position: absolute;
  }
  .visibility-btn:hover {
    background-color: var(--color-link-blue);
  }

  &.open {
    width: 250px;
  }

  &.closed {
    width: 0px;
    padding-right: 0;
    .content {
      overflow: hidden;
      pointer-events: none;
    }
    .visibility-btn {
      right: -10px;
    }
  }
}

@media screen and (max-width: 800px) {
  .date-selection.closed {
    position: absolute;
    left: 20px;
    padding: 0;
  }
}
