.overlay-bg {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
}
.overlay {
  z-index: 3;
  position: fixed;
  background: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.overlay-content {
  box-sizing: border-box;
  pointer-events: all;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  width: 320px;
  max-width: 100%;
  padding: 40px 20px;
  text-align: left;
  background-color: #fff;
}

.overlay-content input {
  width: 100%;
}

.overlay-content .button {
  margin-top: 20px;
}

.overlay-content label {
  margin-bottom: 0;
}

@media only screen and (min-width: 500px) {
  .overlay-content {
    top: 25px;
    border-radius: 10px;
    margin: 30px auto;
  }
}

label[for="remember"] {
  display: inline-block;
  margin-right: 10px;
}

#remember {
  width: fit-content;
}
