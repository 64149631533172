nav {
}
nav ul {
  display: flex;
  padding: 0;
}

nav li {
  list-style: none;
}

nav li a {
  margin: 15px;
  color: var(--color-text-black);
  font-size: 18px;
  padding: 4px 4px 0;
  text-decoration: none;
}

nav li a:visited {
  color: var(--color-text-black);
}

nav li:first-child a {
  margin-left: 0;
}

nav li a:hover {
  border-bottom: 3px solid var(--color-link-blue);
  text-decoration: none;
}

nav li.active a {
  border-bottom: 3px solid var(--color-link-blue);
  pointer-events: none;
  cursor: inherit;
}

@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */
}
